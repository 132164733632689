


















import {
  Component, Vue,
} from 'vue-property-decorator';
import { StatusAccountOnValidationViewModel }
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/change-bank-account/status/status-account-on-validation-view-model';

@Component({ name: 'StatusAccountOnValidation' })
export default class StatusAccountOnValidation extends Vue {
  status_account_on_validation_model = Vue.observable(
    new StatusAccountOnValidationViewModel(),
  );
}
